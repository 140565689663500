import { Action, createReducer, on } from '@ngrx/store';
import { UserState } from '@ra-state';
import { setUserPreferences, setSecondaryEmailsSuccess, setOrganizations, setUserId } from './user.actions';

export const initialUserState: UserState = {
  userId: '',
  name: '',
  location: '',
  email: '',
  company: '',
  idpConnectionName: '',
  secondaryEmails: [],
  organizations: [],
};

const userActions = createReducer(
  initialUserState,
  on(
    setUserPreferences,
    (state: UserState, { userState }): UserState => ({
      ...state,
      ...userState,
    }),
  ),
  on(
    setSecondaryEmailsSuccess,
    (state: UserState, { secondaryEmails }): UserState => ({
      ...state,
      secondaryEmails,
    }),
  ),
  on(
    setOrganizations,
    (state: UserState, { organizations }): UserState => ({
      ...state,
      organizations,
    }),
  ),
  on(
    setUserId,
    (state: UserState, { userId }): UserState => ({
      ...state,
      userId,
    }),
  ),
);

export function userStateReducer(state: UserState, action: Action): any {
  return userActions(state, action);
}
