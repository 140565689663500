import { Action, createReducer, on } from "@ngrx/store";
import { displayMessage } from "./snackbar.action";
import { SnackBarMessage } from "@ra-state";

export const initialSnackbarState: SnackBarMessage = {};

const snackBarData = createReducer(
  initialSnackbarState,
  on(
    displayMessage,
    (state: SnackBarMessage, { payload }): SnackBarMessage => ({
      ...state,
      data: payload,
    })
  )
);

export function snackbarDataReducer(
  state: SnackBarMessage,
  action: Action
): any {
  return snackBarData(state, action);
}
