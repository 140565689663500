import { createSelector } from '@ngrx/store';
import { AppState, FetaureFlags } from '@ra-state';

export const selectFeatureFlagsFeature = (state: AppState): FetaureFlags => {
  return state.featureFlags;
};

export const selectFlags = createSelector(selectFeatureFlagsFeature, (state: FetaureFlags) => {
  return state.flags;
});
