import { createSelector } from "@ngrx/store";
import { HubConnectionState } from "@microsoft/signalr";
import { AppState, SignalRData } from "@ra-state";

export const selectSignalRFeature = (state: AppState): any => state.signalR;

export const selectIsHubConnected = createSelector(
  selectSignalRFeature,
  (state: SignalRData) => state.connectionState === HubConnectionState.Connected
);
export const selectDomainMessage = createSelector(
  selectSignalRFeature,
  (state: SignalRData) => state.domainMessage
);
export const selectHubDisconnected = createSelector(
  selectSignalRFeature,
  (state: SignalRData) =>
    state.connectionState === HubConnectionState.Disconnected
);
