import { Action, createReducer, on } from '@ngrx/store';
import { loading, setProductCatalogSuccess, userLoginComplete } from './control-page.actions';
import { PageControl } from '@ra-state';

export const initialPageControlState: PageControl = { isLoading: false };

const pageActions = createReducer(
  initialPageControlState,
  on(
    loading,
    (state: PageControl, { value }): PageControl => ({
      ...state,
      isLoading: value,
    }),
  ),
  on(
    userLoginComplete,
    (state: PageControl, { payload }): PageControl => ({
      ...state,
      loggedInUserProfile: payload,
    }),
  ),
  on(
    setProductCatalogSuccess,
    (state: PageControl, { payload }): PageControl => ({
      ...state,
      catalogInfo: payload,
    }),
  ),
);

export function controlPageReducer(state: PageControl, action: Action): any {
  return pageActions(state, action);
}
