import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserProfile } from '@rockwell-automation-inc/service';
import { AppState, ErrorContext } from '@ra-state';
import { selectCatalogInfo, selectLoadingState, selectLoggedInUserProfile } from './control-page.selectors';
import { loading, navigateToError, userLoginComplete } from './control-page.actions';

@Injectable({
  providedIn: 'root',
})
export class ControlPageFacade {
  isLoading$ = this.store$.select(selectLoadingState);
  loggedInUserProfile$ = this.store$.select(selectLoggedInUserProfile);
  catalogInfo$ = this.store$.select(selectCatalogInfo);

  setLoading(value: boolean): void {
    this.store$.dispatch(loading({ value }));
  }

  setUserLoggedIn(userProfile: UserProfile): void {
    this.store$.dispatch(userLoginComplete({ payload: userProfile }));
  }

  navigateToError(httpError: ErrorContext): void {
    this.store$.dispatch(navigateToError({ payload: httpError }));
  }

  constructor(private store$: Store<AppState>) {}
}
