import { createAction, props } from '@ngrx/store';
import { Organizations, UserState } from '../common-admin.model';
export const getUserPreferences = createAction('[USER] Get Preferences', props<{ userId: string }>());

export const setUserPreferences = createAction('[USER] Set Preferences', props<{ userState: UserState }>());

export const updateSecondaryEmails = createAction(
  '[USER] Update Secondary Emails',
  props<{ userId: string; secondaryEmails: string[] }>(),
);

export const setSecondaryEmailsSuccess = createAction(
  '[USER] Set Secondary Emails',
  props<{ secondaryEmails: string[] }>(),
);

export const getOrganizations = createAction('[USER] Get User Organizations', props<{ userId: string }>());

export const setOrganizations = createAction(
  '[USER] Set User Organizations',
  props<{ organizations: Organizations[] }>(),
);

export const setUserId = createAction('[USER] Set User Id', props<{ userId: string }>());
