import { createSelector } from '@ngrx/store';
import { AppState, PageControl } from '@ra-state';

export const selectPageControlFeature = (state: AppState): PageControl => state.controlPage;

export const selectLoadingState = createSelector(selectPageControlFeature, (state: PageControl) => state.isLoading);
export const selectLoggedInUserProfile = createSelector(
  selectPageControlFeature,
  (state: PageControl) => state.loggedInUserProfile,
);
export const selectCatalogInfo = createSelector(selectPageControlFeature, (state: PageControl) => state.catalogInfo);
