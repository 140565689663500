import { createSelector } from '@ngrx/store';
import { AppState, SessionManagement } from '@ra-state';

export const selectSessionManagementFeature = (state: AppState): SessionManagement => state.sessionManagement;
export const selectUserActivityRecorded = createSelector(
  selectSessionManagementFeature,
  (state: SessionManagement) => state.userActivityRecorded,
);
export const selectUserActivityRecordedAt = createSelector(
  selectSessionManagementFeature,
  (state: SessionManagement) => state.lastUserActivityRecordedAt,
);
export const selectIsUserActive = createSelector(
  selectSessionManagementFeature,
  (state: SessionManagement) => state.isUserActive,
);
export const selectSessionTimedOut = createSelector(
  selectSessionManagementFeature,
  (state: SessionManagement) => state.sessionTimedOut,
);
export const selectExtendSession = createSelector(
  selectSessionManagementFeature,
  (state: SessionManagement) => state.extendSession,
);
export const selectLogoutSession = createSelector(
  selectSessionManagementFeature,
  (state: SessionManagement) => state.logoutSession,
);
