import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { SnackBarModule } from './snackbar/snackbar.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/components';
import { AuthService } from '@rockwell-automation-inc/service';
import { DataService } from '@services/data.service';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';
import { HarnessService } from '@services/harness.service';

@NgModule({
  declarations: [SpinnerComponent],
  exports: [SnackBarModule, SpinnerComponent],
  imports: [CommonModule, HttpClientModule, RouterModule, SnackBarModule, RaUiLoadingSpinnerModule],
  providers: [AuthService, DataService, HarnessService],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
