import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ENV_VARS, getEnvOrDefault } from '@app/shared/env';
import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(getEnvOrDefault(ENV_VARS.AG_GRID_LICENSE, 'invalid'));
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
