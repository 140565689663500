import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { DialogComponent, DialogService, IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { openConfirmDialog, openAlertDialog } from './modal.action';
import { Action } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@services/modal.service';
import { emptyAction } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class ModalEffects {
  openDialog(config: IDialogConfig, dialogType: NotificationType): MatDialogRef<DialogComponent> {
    switch (dialogType) {
      case NotificationType.Success:
        return this.dialogService.openSuccessDialog(config);
      case NotificationType.Info:
        return this.dialogService.openInfoDialog(config);
      case NotificationType.Warning:
        return this.dialogService.openWarningDialog(config);
      case NotificationType.Error:
        return this.dialogService.openErrorDialog(config);
      default:
        return this.dialogService.openDialog(config);
    }
  }

  openConfirmDialog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(openConfirmDialog),
      switchMap((payload) => {
        const confirmDialogConfig = payload.payload;
        const diaglogRef = this.openDialog(confirmDialogConfig.config, confirmDialogConfig.type);
        return combineLatest([diaglogRef.componentInstance.onClick, of(payload.payload)]);
      }),
      switchMap(([dialogBtn, payload]) => {
        const actions: Action[] = [];
        if (dialogBtn.label === 'Confirm') {
          actions.push(payload.action);
        }
        return actions;
      }),
    );
  });

  openAlertDialog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(openAlertDialog),
      map((payload) => {
        const confirmDialogConfig = payload.payload;
        this.modalService.showModal(confirmDialogConfig);
        return emptyAction();
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private dialogService: DialogService,
    private modalService: ModalService,
  ) {}
}
