import { createAction, props } from '@ngrx/store';

export const checkUserActive = createAction('[SESSION MANAGEMENT] Check User Activity In Session');
export const setUserActivityRecorded = createAction(
  '[SESSION MANAGEMENT] User Activity Recorded',
  props<{ value: boolean }>(),
);
export const setUserActive = createAction('[SESSION MANAGEMENT] Set User Active', props<{ value: boolean }>());
export const recordUserActivity = createAction('[SESSION MANAGEMENT] Record Session Activity');
export const endUserSession = createAction('[SESSION MANAGEMENT] End User Session');
export const extendUserSession = createAction('[SESSION MANAGEMENT] Extend User Session');
