/* eslint-disable no-console */
import { InjectionToken } from '@angular/core';

export type LoggerFactory = (f: Function) => LoggerService;
export const LoggerFactoryToken = new InjectionToken<LoggerFactory>('Logger');
export class LoggerService {
  private ctx: string[] = [];

  writeLog(logfn: Function, msg: any, ...optionalParams: any[]): void {
    if (this.isProduction) {
      return;
    }
    if (this.ctx.length === 0) {
      logfn.call(this, ...[msg, ...optionalParams]);
    } else {
      logfn.call(this, ...[`[${this.ctx.join('.')}]`, msg, ...optionalParams]);
    }
  }
  log(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.info, msg, ...optionalParams);
  }

  error(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.error, msg, ...optionalParams);
  }

  warn(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.warn, msg, ...optionalParams);
  }
  debug(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.debug, msg, ...optionalParams);
  }
  withContext(childCtx: string | Function): LoggerService {
    if (typeof childCtx === 'function') {
      return this.withContext(childCtx.name);
    }
    const logger = new LoggerService(this.isProduction);
    logger.ctx = [...this.ctx, childCtx];
    return logger;
  }
  public static for(isProduction: boolean): Function {
    return (ctx: Function): LoggerService => {
      return new LoggerService(isProduction).withContext(ctx.name);
    };
  }
  private constructor(private isProduction: boolean) {}
}
