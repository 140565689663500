import { controlPageReducer } from './control-page';
import { signalrReducer } from './signalr';
import { snackbarDataReducer } from './snackbar/snackbar.reducer';
import { routerReducer } from '@ngrx/router-store';
import { trialsReducer } from './trials/trials.reducer';
import { userStateReducer } from './user';
import { agGridReducer } from './ag-grid';
import { tenantStateReducer } from './tenant';
import { featureFlagsReducer } from './feature-flags/feature-flags.reducer';
import { sessionManagementReducer } from './session-management/session-management.reducer';

export const reducers = {
  controlPage: controlPageReducer,
  signalR: signalrReducer,
  router: routerReducer,
  snackBarData: snackbarDataReducer,
  user: userStateReducer,
  trials: trialsReducer,
  agGrid: agGridReducer,
  tenant: tenantStateReducer,
  featureFlags: featureFlagsReducer,
  sessionManagement: sessionManagementReducer,
};
