import { NgModule } from '@angular/core';
import { SnackBarComponent } from './snackbar.component';
import { RaUiToastModule } from '@ra-web-tech-ui-toolkit/components';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SnackBarComponent],
  exports: [SnackBarComponent, RaUiToastModule],
  imports: [CommonModule, RaUiToastModule, RouterModule],
})
export class SnackBarModule {}
