import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectTenantId,
  selectTenantUtilityTokenEntitlements,
  selectTenantUsers,
  selectTenantInfo,
  selectFilteredTenantUsers,
} from './tenant.selectors';
import { skipWhile } from 'rxjs';
import { AppState, InviteAdmin, PaginatedRequest, TenantUser, TransferOwnership } from '@ra-state';

import {
  createAdminInvitation,
  getTenantDetails,
  getTenantFilteredUsers,
  getTenantUsers,
  getTenantUtilityTokens,
  setTenantId,
  setTenantUsers,
  transferOwnership,
} from './tenant.actions';

@Injectable({
  providedIn: 'root',
})
export class TenantFacade {
  tokenEntitlements$ = this.store$
    .select(selectTenantUtilityTokenEntitlements)
    .pipe(skipWhile((tokenEntitlements) => tokenEntitlements === undefined));
  tenantId$ = this.store$.select(selectTenantId);
  tenantUsers$ = this.store$.select(selectTenantUsers);
  filteredTenantUsers$ = this.store$.select(selectFilteredTenantUsers);
  tenantDetails$ = this.store$.select(selectTenantInfo);

  getTenantUtilityTokens(tenantId: string): void {
    this.store$.dispatch(getTenantUtilityTokens({ value: tenantId }));
  }

  setTenantId(tenantId: string): void {
    this.store$.dispatch(setTenantId({ tenantId }));
  }

  getTenantUsers(payload: PaginatedRequest): void {
    this.store$.dispatch(getTenantUsers({ payload }));
  }

  setTenantUsers(tenantUsers: TenantUser[]): void {
    this.store$.dispatch(setTenantUsers({ tenantUsers }));
  }

  getTenantFilteredUsers(payload: PaginatedRequest): void {
    this.store$.dispatch(getTenantFilteredUsers({ payload }));
  }

  getTenantDetails(tenantId: string): void {
    this.store$.dispatch(getTenantDetails({ value: tenantId }));
  }

  transferOwnership(payload: TransferOwnership): void {
    this.store$.dispatch(transferOwnership({ payload: payload }));
  }

  inviteUserAsAdmin(invite: InviteAdmin): void {
    this.store$.dispatch(createAdminInvitation({ payload: invite }));
  }

  constructor(private store$: Store<AppState>) {}
}
