import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermHelperService, Permission, PermissionCondition } from '@app/services/perm-helper.service';
import { LoggerFactoryToken } from '@services/logger.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermGuard implements CanActivate {
  logger = inject(LoggerFactoryToken)(PermGuard);
  constructor(private permissionHelper: PermHelperService<Permission>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredPerms = route.data['requiredPerms'] as PermissionCondition<Permission>;
    this.logger.log('Required Permissions', requiredPerms);
    return this.permissionHelper.checkPermission$(requiredPerms);
  }
}
