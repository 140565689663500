import { createAction, props } from '@ngrx/store';
import { ErrorContext, ICatalogInfo, Version } from '@ra-state';
import { UserProfile } from '@rockwell-automation-inc/service';

export const loading = createAction('[CONTROL_PAGE] Set Loading', props<{ value: boolean }>());

export const userLoginComplete = createAction('[CONTROL_PAGE] UserLoggedIn', props<{ payload: UserProfile }>());

export const navigateTo = createAction('[Navigate] Navigate To Route', props<{ path: string }>());

export const navigateToError = createAction('[Error] Navigate To Error', props<{ payload: ErrorContext }>());

export const setErrorContext = createAction('[Error] Set Error Context', props<{ payload: ErrorContext }>());

export const getVersions = createAction('[APIVersion] Get Version');

export const setVersions = createAction('[APIVersion] Set Version', props<{ value: Version }>());

export const getProductCatalog = createAction('[CATALOG] Get Catalog Info');
export const setProductCatalogSuccess = createAction(
  '[CATALOG] Get Catalog Info Success',
  props<{ payload: ICatalogInfo[] }>(),
);

export const emptyAction = createAction('[EMTPY] Empty Action');

export const startAppInitializer = createAction('[App] Starting App');

export const AppInitFinished = createAction('[App] Rockwell-automation-inc library loaded');
