<ng-container *ngIf="userInfo$ | async as userInfo">
  <h1>FactoryTalk Hub Admin Portal</h1>
  <h3>Hello, {{ userInfo?.name }}</h3>
</ng-container>
<ng-container *ngIf="isHubConnected$ | async"> </ng-container>
<div>
  FactoryTalk Hub's admin portal allows you to:
  <ul>
    <li *appPerms="[Permission.CreateServiceRequest]"><a [routerLink]="['/trials']">Trial Campaigns Management</a></li>
    <li *appPerms="[Permission.ListEntitlement]"><a [routerLink]="['/entitlement']">Lookup Entitlements</a></li>
    <li *appPerms="[Permission.ListTenant]"><a [routerLink]="['/organizations']">Lookup Organizations</a></li>
    <li *appPerms="[Permission.ListUser]"><a [routerLink]="['/users']">Lookup Hub Users</a></li>
  </ul>
</div>
