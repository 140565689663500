import { inject, Injectable } from '@angular/core';
import { LoggerFactoryToken } from '@services/logger.service';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
  AppInitFinished,
  getProductCatalog,
  setProductCatalogSuccess,
  startAppInitializer,
  userLoginComplete,
} from './control-page.actions';
import { Observable, switchMap, tap } from 'rxjs';
import { connect, ICatalogInfo, loading, signalRHubConnected } from '@ra-state';
import { CommandRequestBuilderService } from 'src/app/services/command-request-builder.service';
import { DataService } from '@services/data.service';
import { Action } from '@ngrx/store';
import { HarnessService } from '@services/harness.service';

@Injectable({
  providedIn: 'root',
})
export class ControlPageEffects implements OnInitEffects {
  initializeApp$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(startAppInitializer),
      tap(() => {
        this.harnessService.initializeTarget({
          identifier: 'common-admin-portal',
          attributes: {
            Type: 'Application',
          },
        });
      }),
      switchMap(() => {
        return [AppInitFinished()];
      }),
    );
  });

  ngrxOnInitEffects(): Action {
    this.logger.log('Initializing effects');
    return startAppInitializer();
  }

  loginCompleted$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      switchMap(() => {
        return [connect(), getProductCatalog(), loading({ value: true })];
      }),
    );
  });

  signalRHubConnected$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(signalRHubConnected),
      switchMap(() => {
        return [loading({ value: false })];
      }),
    );
  });

  getProductCatalog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getProductCatalog),
      switchMap(() => {
        return this.dataService.getCatalogInfo$();
      }),
      switchMap((catalog: ICatalogInfo[]) => {
        return [setProductCatalogSuccess({ payload: catalog })];
      }),
    );
  });

  logger = inject(LoggerFactoryToken)(ControlPageEffects);
  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private commandRequestBuilderService: CommandRequestBuilderService,
    private harnessService: HarnessService,
  ) {
    this.logger.log('control page effects loads');
  }
}
