import { createSelector } from '@ngrx/store';
import { AppState, UserState } from '@ra-state';

export const selectUserFeature = (state: AppState): UserState => state.user;

export const selectUserState = createSelector(selectUserFeature, (state: UserState) => state);

export const selectSecondaryEmails = createSelector(selectUserFeature, (state: UserState) => state.secondaryEmails);

export const selectOrganizations = createSelector(selectUserFeature, (state: UserState) =>
  state.organizations.filter((organization) => organization.name !== 'Personal Tenant'),
);

export const selectUserId = createSelector(selectUserFeature, (state: UserState) => state.userId);
