import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../common-admin.model';
import {
  endUserSession,
  selectExtendSession,
  selectIsUserActive,
  selectLogoutSession,
  selectSessionTimedOut,
  extendUserSession,
  selectUserActivityRecorded,
  setUserActivityRecorded,
  selectUserActivityRecordedAt,
} from '@ra-state';
@Injectable({
  providedIn: 'root',
})
export class SessionManagementFacade {
  userActivityRecorded$ = this.store$.select(selectUserActivityRecorded);
  userActivityRecordedAt$ = this.store$.select(selectUserActivityRecordedAt);
  userActive$ = this.store$.select(selectIsUserActive);
  sessionTimedOut$ = this.store$.select(selectSessionTimedOut);
  extendSession$ = this.store$.select(selectExtendSession);
  logoutSession$ = this.store$.select(selectLogoutSession);

  extendUserSession(): void {
    this.store$.dispatch(extendUserSession());
  }

  endUserSession(): void {
    this.store$.dispatch(endUserSession());
  }

  setUserActivityRecorded(value: boolean): void {
    this.store$.dispatch(setUserActivityRecorded({ value: value }));
  }

  constructor(private store$: Store<AppState>) {}
}
