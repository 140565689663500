import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { displayMessage } from './snackbar.action';
import { selectDisplayMessage } from './snackbar.selectors';
import { filter } from 'rxjs/operators';
import { AppState, SnackBarData } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class SnackBarFacade {
  displayMessage$ = this.store$.select(selectDisplayMessage).pipe(filter((message) => message !== undefined));

  displayMessage(messageData: SnackBarData): void {
    this.store$.dispatch(displayMessage({ payload: messageData }));
  }

  constructor(private store$: Store<AppState>) {}
}
