import { Action, createReducer, on } from '@ngrx/store';
import { AgGridState } from '../common-admin.model';
import { refreshAgGridData } from './ag-grid.action';

export const agInitialState: AgGridState = { refreshAgGrid: false };

const agGridActions = createReducer(
  agInitialState,
  on(
    refreshAgGridData,
    (state: AgGridState, { value }): AgGridState => ({
      ...state,
      refreshAgGrid: value,
    }),
  ),
);

export function agGridReducer(state: AgGridState, action: Action): any {
  return agGridActions(state, action);
}
