import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogService, IDialogConfig } from '@ra-web-tech-ui-toolkit/components';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public dialogRef: MatDialogRef<DialogComponent>;

  constructor(private readonly dialogSvc: DialogService) {}

  showModal(config: IDialogConfig): MatDialogRef<DialogComponent> {
    const dialogConfig: IDialogConfig = {
      ...config,
    };
    this.dialogRef = this.dialogSvc.openDialog(dialogConfig);
    return this.dialogRef;
  }
}
