import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, finalize } from "rxjs";
import { ControlPageFacade } from "../+state/control-page";
import { HttpMethod } from "@auth0/auth0-angular";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private cache = new Map<string, any>();
  timerId: any;

  constructor(private readonly controlPageFacade: ControlPageFacade) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.method !== HttpMethod.Get) {
      return next.handle(req);
    }

    const showSpinner = req.headers.get("show_spinner");
    if (showSpinner === null) {
      this.controlPageFacade.setLoading(true);
    } else {
      this.controlPageFacade.setLoading(false);
    }
    this.cache.set(req.url, req.method);
    if (this.cache.size > 0) {
      clearTimeout(this.timerId);
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.cache.delete(req.url);
        if (this.cache.size === 0) {
          this.timerId = setTimeout(() => {
            this.controlPageFacade.setLoading(false);
          }, 500);
        }
      })
    );
  }
}
