import { Action, createReducer, on } from '@ngrx/store';
import { disconnect, setDomainMessageReceived, setConnectionState, updateConnectionState } from './signalr.actions';
import { SignalRData } from '../common-admin.model';

export const initialSignalRState: SignalRData = {};

const reducer = createReducer<SignalRData>(
  initialSignalRState,
  on(
    setConnectionState,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      connectionState: payload,
    }),
  ),
  on(
    updateConnectionState,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      connectionState: payload,
    }),
  ),
  on(
    setDomainMessageReceived,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      domainMessage: payload,
    }),
  ),
  on(disconnect, (state: SignalRData): SignalRData => {
    return { ...state };
  }),
);

export function signalrReducer(state: SignalRData, action: Action): any {
  return reducer(state, action);
}
