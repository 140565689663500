import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSecondaryEmails, selectUserId, selectUserState, selectOrganizations } from './user.selectors';
import { AppState, Organizations, UserState, selectTenantInfo } from '@ra-state';
import {
  getOrganizations,
  getUserPreferences,
  setOrganizations,
  setUserId,
  setUserPreferences,
  updateSecondaryEmails,
} from './user.actions';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  user$ = this.store$.select(selectUserState);
  secondaryEmails$ = this.store$.select(selectSecondaryEmails);

  organizations$ = this.store$.select(selectOrganizations);
  userId$ = this.store$.select(selectUserId);
  selectTenantInfo$ = this.store$.select(selectTenantInfo);

  getUserPreferences(userId: string): void {
    this.store$.dispatch(getUserPreferences({ userId }));
  }

  setUserPreferences(userState: UserState): void {
    this.store$.dispatch(setUserPreferences({ userState }));
  }

  setSecondaryEmails(userId: string, secondaryEmails: string[]): void {
    this.store$.dispatch(updateSecondaryEmails({ userId, secondaryEmails }));
  }

  getOrganizations(userId: string): void {
    this.store$.dispatch(getOrganizations({ userId }));
  }

  setOrganizations(organizations: Organizations[]): void {
    this.store$.dispatch(setOrganizations({ organizations }));
  }

  setUserId(userId: string): void {
    this.store$.dispatch(setUserId({ userId }));
  }

  constructor(private store$: Store<AppState>) {}
}
