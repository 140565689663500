import { CoreConfiguration } from '@rockwell-automation-inc/service';

const appConfiguration: CoreConfiguration = {
  production: false,
  name: 'Common Admin',
  csApiBaseUrl: 'https://common.lemans-sandbox.rockwellautomation.com/api',
  csPortalUrl: 'https://home.lemans-sandbox.rockwellautomation.com',
  notificationsBaseUrl: 'https://api-notifications.lemans-sandbox.rockwellautomation.com',
  authConfig: {
    domain: 'factorytalkhub-dev.us.auth0.com',
    clientId: 'u6DI8DyO4gNOYQn73FRsT6TW5vhMSz5v',
    audience: 'https://lemans.common',
    redirectUri: 'https://common-admin.lemans-sandbox.rockwellautomation.com/callback',
    connection: 'Rockwell-SP-Admin',
  },
  returnToAfterLogout: 'https://common-admin.lemans-sandbox.rockwellautomation.com/sign-in',
  customSignInUrl: 'https://common-admin.lemans-sandbox.rockwellautomation.com/sign-in',
};

export const environment = {
  production: false,
  appConfiguration,
  ftHubPortalUrl: 'https://home.lemans-sandbox.rockwellautomation.com',
};
