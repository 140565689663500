import { Action, createReducer, on } from '@ngrx/store';
import { setUserActive, setUserActivityRecorded } from './session-management.actions';
import { SessionManagement } from '../common-admin.model';

export const initialSessionManagementState: SessionManagement = {
  userActivityRecorded: false,
  sessionTimedOut: false,
  extendSession: false,
  logoutSession: false,
  lastUserActivityRecordedAt: Date.now(),
};

const sessionManagementActions = createReducer(
  initialSessionManagementState,
  on(setUserActivityRecorded, (state: SessionManagement, { value }): SessionManagement => {
    if (value === true) {
      return { ...state, userActivityRecorded: value, lastUserActivityRecordedAt: Date.now() };
    }
    return { ...state, userActivityRecorded: value };
  }),
  on(setUserActive, (state: SessionManagement, { value }): SessionManagement => ({ ...state, isUserActive: value })),
);

export function sessionManagementReducer(state: SessionManagement, action: Action): any {
  return sessionManagementActions(state, action);
}
