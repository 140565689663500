import { HubConnectionState } from "@microsoft/signalr";
import { createAction, props } from "@ngrx/store";
import { IDomainMessage } from "@ra-state";

export const connect = createAction("[SignalR] Set Connection");
export const disconnect = createAction("[SignalR] Stop Connection");
export const setConnectionState = createAction(
  "[SignalR] Set Connection State",
  props<{ payload: HubConnectionState }>()
);

export const signalRHubConnected = createAction(
  "[SignalR] SignalR Hub connected"
);

export const updateConnectionState = createAction(
  "[SignalR] Update Connection State",
  props<{ payload: HubConnectionState }>()
);

export const setDomainMessageReceived = createAction(
  "[SignalR] Domain message received",
  props<{ payload: IDomainMessage }>()
);
