import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRefreshAgGrid } from './ag-grid.selector';
import { AppState } from '../common-admin.model';
import { refreshAgGridData } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class AgGridFacade {
  refreshAgGrid$ = this.store$.select(selectRefreshAgGrid);

  constructor(private store$: Store<AppState>) {}

  refreshAgGrid(isDataRefresh: boolean): void {
    this.store$.dispatch(refreshAgGridData({ value: isDataRefresh }));
  }
}
