import { createAction, props } from '@ngrx/store';
import { CampaignDetails, CancelTrial, CreateCampaign, ReserveTrial } from '@ra-state';

export const reserveTrial = createAction('[TRIALS] Reserve Trial', props<{ payload: ReserveTrial }>());

export const cancelReservation = createAction('[TRIALS] Cancel Trial Reservation', props<{ payload: CancelTrial }>());

export const createCampaign = createAction('[TRIALS] Create Trial Campaign', props<{ payload: CreateCampaign }>());

export const getCampaignDetails = createAction('[TRIALS] Get Campaign Details', props<{ payload: string }>());
export const setCampaignDetails = createAction('[TRIALS] Set Campaign Details', props<{ payload: CampaignDetails }>());

export const pauseCampaign = createAction('[TRIALS] Pause Trial Campaign', props<{ campaignId: string }>());
export const resumeCampaign = createAction('[TRIALS] Resume Trial Campaign', props<{ campaignId: string }>());
export const endCampaign = createAction('[TRIALS] End Trial Campaign', props<{ campaignId: string }>());
export const getAvailableProductCatalogs = createAction(
  '[TRIALS] Get Available Product Catalogs',
  props<{ payload: string }>(),
);
export const setAvailableProductCatalogs = createAction(
  '[TRIALS] Set Available Product Catalogs',
  props<{ payload: string[] }>(),
);
