import { Action, createReducer, on } from '@ngrx/store';
import { setAvailableProductCatalogs, setCampaignDetails } from './trials.action';
import { Trials } from '@ra-state';

export const initialTrialsState: Trials = {
  campaignDetails: {
    campaignCredits: 0,
    perTrialCredits: 0,
    remainingCredits: 0,
    consumedCredits: 0,
    name: '',
    description: '',
    trialDurationInDays: 0,
    trialRedemptionLimitInDays: 0,
    createdOn: '',
    createdByUserId: '',
    createdByUser: '',
    status: '',
    endedOn: '',
    endedByUserId: '',
    endedByUser: '',
    pausedOn: '',
    pausedByUserId: '',
    pausedByUser: '',
    reservedTrials: 0,
    redeemedTrials: 0,
    trialsQuantity: 0,
    serviceKind: '',
    id: '',
    partitionId: '',
    viewType: '',
    remainingQuantity: 0,
    catalogNumber: '',
  },
  productCatalogs: [],
};

const trialsData = createReducer(
  initialTrialsState,
  on(
    setCampaignDetails,
    (state: Trials, { payload }): Trials => ({
      ...state,
      campaignDetails: payload,
    }),
  ),
  on(
    setAvailableProductCatalogs,
    (state: Trials, { payload }): Trials => ({
      ...state,
      productCatalogs: payload,
    }),
  ),
);

export function trialsReducer(state: Trials, action: Action): any {
  return trialsData(state, action);
}
