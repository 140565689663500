import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule, MultiTranslateHttpLoader, TranslationAsset } from '@rockwell-automation-inc/layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ConfigService,
  CoreConfiguration,
  ServiceConfigProvider,
  ServiceModule,
} from '@rockwell-automation-inc/service';
import { environment } from '@environments/environment';
import { DataService } from './core/services/data.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/components';
import {
  reducers,
  ControlPageEffects,
  SignalREffects,
  SnackBarEffects,
  TrialsEffects,
  TenantEffects,
  FeatureFlagsEffects,
  UserEffects,
  SessionManagementEffects,
} from '@ra-state';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './+state/custom-serializer';
import { CoreModule } from './core/core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AgLinkRendererComponent } from './shared/ag-renderers/ag-link-renderer.component';
import { LoggerFactoryToken, LoggerService } from '@services/logger.service';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { ModalEffects } from './+state/modal';

@Injectable({ providedIn: 'root' })
export class ServiceConfigFromApp implements ServiceConfigProvider {
  constructor(private configService: ConfigService<CoreConfiguration>) {}
  get CoreConfiguration(): CoreConfiguration {
    return this.configService.config;
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  const translationResouceList: TranslationAsset[] = [{ path: './assets/i18n/' }];
  return new MultiTranslateHttpLoader(http, translationResouceList);
}

@NgModule({
  declarations: [AppComponent, AgLinkRendererComponent],
  imports: [
    BrowserModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en-US',
      useDefaultLang: true,
    }),
    CoreModule,
    ServiceModule.forRoot(environment.appConfiguration),
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    AppRoutingModule,
    LayoutModule,
    MatSidenavModule,
    RaUiButtonModule,
    EffectsModule.forRoot([
      ControlPageEffects,
      SignalREffects,
      SnackBarEffects,
      UserEffects,
      TrialsEffects,
      ModalEffects,
      TenantEffects,
      FeatureFlagsEffects,
      SessionManagementEffects,
    ]),
  ],
  providers: [
    DataService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigService<CoreConfiguration>): any =>
        () =>
          configService.setConfig(environment.appConfiguration),
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    {
      provide: LoggerFactoryToken,
      useFactory: (): any => LoggerService.for(environment.appConfiguration.production),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
