import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, skipWhile } from 'rxjs';
import { AppState } from '../common-admin.model';
import { selectDomainMessage, selectHubDisconnected, selectIsHubConnected } from './signalr.selectors';
@Injectable({
  providedIn: 'root',
})
export class SignalRFacade {
  isHubConnected$ = this.store$.select(selectIsHubConnected).pipe(skipWhile((isConnected) => isConnected === false));
  hubDisconnected$ = this.store$.select(selectHubDisconnected).pipe(skipWhile((connState) => connState === false));
  domainMessage$ = this.store$.select(selectDomainMessage).pipe(filter((domainMessage) => domainMessage !== undefined));

  constructor(private store$: Store<AppState>) {}
}
