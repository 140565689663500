<lib-landing (signInClick)="signInClicked()" *ngIf="(authService.isAuthenticated$ | async) === false"></lib-landing>
<div class="app-container" *ngIf="userLoggedIn$ | async">
  <ng-template *ngIf="(userId$ | async) && (userInfo$ | async)"></ng-template>
  <lib-common-mat-navbar
    #commonNavbar
    [displaySlideToggleTheme]="false"
    [initialTheme]="logoTypeTheme"
    [logotypeClass]="logoTypeThemeClass"
    [isAuthenticated]="(authService.isAuthenticated$ | async) || false"
    (raLogoClick)="goToHome()"
    (goToHomeClick)="goToHome()"
    (menuItemClick)="menuItemClick($event)"
    [enableUserActionsSideNav]="true"
    [navItems]="(navItems$ | async) || []"
    [enableUserProfile]="true"
    [accountMenu]="userInfo"
    [showBanner]="showBanner"
    [bannerMessage]="bannerMessage"
    [bannerButtons]="bannerButtons"
    [bannerConfig]="bannerConfig"
    [bannerRaIcon]="bannerIcon"
    (logoutClick)="logoutClicked()"
  >
    <div class="app-content-container">
      <div class="main-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </lib-common-mat-navbar>
</div>

<app-spinner *ngIf="isLoading$ | async"></app-spinner>
<app-snackbar></app-snackbar>

<ng-container *ngIf="sessionTimedOut$ | async"></ng-container>
<ng-container *ngIf="countDownTimer$ | async"></ng-container>
