import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { deleteFeatureFlag, setFeatureFlags, updateFeatureFlag } from './feature-flags.actions';
import { AppState, FeatureFlag, FeatureFlagUpdate, FlagValue } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
/* 
  This facade should only be used by harness service to react to harness service events
*/
export class HarnessFacade {
  constructor(private store$: Store<AppState>) {}

  setFeatureFlags(flags: Record<string, FlagValue>): void {
    this.store$.dispatch(setFeatureFlags({ flags: flags }));
  }

  updateFeatureFlag(update: FeatureFlagUpdate): void {
    this.store$.dispatch(updateFeatureFlag({ payload: update }));
  }

  deleteFeatureFlag(flag: FeatureFlag): void {
    this.store$.dispatch(deleteFeatureFlag({ payload: flag }));
  }
}
